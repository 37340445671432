const Banner = (state = {
    banner: [],
    bannerList: []
}, action) => {
    switch (action.type) {
        case 'GET_BANNER':
            return {
                ...state,
                banner: action.payload
            }
            break;
        case 'GET_BANNER_LIST':
            return {
                ...state,
                bannerList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Banner;
