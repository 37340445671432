const FirebaseConfig = {
    apiKey: "AIzaSyBZ50f4LYc9U-A6IkqcFxOlVhgMBfh84QE",
    authDomain: "heylinko.firebaseapp.com",
    projectId: "heylinko",
    storageBucket: "heylinko.appspot.com",
    messagingSenderId: "393731850609",
    appId: "1:393731850609:web:24d7e862cc01ce809bf6d0",
    measurementId: "G-361XQGZYNZ"
};

export default FirebaseConfig;
