const Countries = (state = {
    list: [],
    selectedCountryObj: {},
    countries: []
}, action) => {
    switch (action.type) {
        case 'GET_LIST':
            return {
                ...state,
                list: action.payload
            };
            break;
        case 'SELECTED_COUNTRY':
            return {
                ...state,
                selectedCountryObj: action.payload
            };
            break;
        case 'GET_COUNTRIES':
            return {
                ...state,
                countries: action.payload
            }
            break;
        default:
            return state;
    }
};


export default Countries;
