const BoostSubscription = (state = {
    subPayments: [],
    subPaymentsList: [],
    boosts: []
}, action) => {
    switch (action.type) {
        case 'SUB_PAYMENTS':
            return {
                ...state,
                subPayments: action.payload
            }
            break;
        case 'SUB_PAYMENTS_LIST':
            return {
                ...state,
                subPaymentsList: action.payload
            }
            break;
        case 'BOOSTS':
            return {
                ...state,
                boosts: action.payload
            }
            break;
        default:
            return state;
    }
};

export default BoostSubscription;
