const Explorer = (state = {
    explorer: [],
    explorerList: [],
}, action) => {
    switch (action.type) {
        case 'GET_EXPLORER':
            return {
                ...state,
                explorer: action.payload
            }
            break;
        case 'GET_EXPLORER_LIST':
            return {
                ...state,
                explorerList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Explorer;
