import React, {useEffect} from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import {db} from "../auth/FirebaseAuth";

function RouteInterceptor({children, isAuthenticated, ...rest}) {

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: AUTH_PREFIX_PATH,
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export const Views = (props) => {
    const {locale, token, location, direction} = props;
    const currentAppLocale = AppLocale[locale];
    useBodyClass(`dir-${direction}`);

    const collectionRef = db.collection('countries');

    function getCountriesData() {
        collectionRef
            .get()
            .then((res) => {
                const countries = res.docs.map(item => ({...item.data(), key: item.id}))
                props.countryListFunc(countries);
                let savedCountry = JSON.parse(localStorage.getItem("selectedCountry"))
                if (countries.length > 0)
                    savedCountry?.shortname? props.selectedCountryFunc(savedCountry) :
                    props.selectedCountryFunc(countries[0])
            });
    }

    useEffect(() => {
        getCountriesData();
    }, []);

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={APP_PREFIX_PATH}/>
                    </Route>
                    <Route path={AUTH_PREFIX_PATH}>
                        <AuthLayout direction={direction}/>
                    </Route>
                    <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                        <AppLayout direction={direction} location={location}/>
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}


const mapStateToProps = ({theme, auth}) => {
    const {locale, direction} = theme;
    const {token} = auth;
    return {locale, direction, token}
};

const mapDispatchToProps = (dispatch) => {
    return {
        countryListFunc: (info) => {
            dispatch({
                type: 'GET_LIST',
                payload: info
            })
        },
        selectedCountryFunc: (country) => {
            dispatch({
                type: 'SELECTED_COUNTRY',
                payload: country
            })
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
