const Manager = (state = {
    manager: {},
}, action) => {
    switch (action.type) {
        case 'MANAGER_URL':
            return {
                ...state,
                manager: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Manager;
