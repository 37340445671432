const StoresAccount = (state = {
    storesAccount: [],
    storesAccountList: [],
    storesSearch:[]
}, action) => {
    switch (action.type) {
        case 'GET_STORES_ACCOUNT':
            return {
                ...state,
                storesAccount: action.payload
            }
            break;
        case 'GET_STORES_LIST_ACCOUNT':
            return {
                ...state,
                storesAccountList: action.payload
            }
            break;
        case 'GET_STORES_IN_ACCOUNT':
            return {
                ...state,
                storesSearch: action.payload
            }
            break;
        default:
            return state;
    }
};

export default StoresAccount;
