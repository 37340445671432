import React from "react";
import {CheckOutlined, GlobalOutlined, DownOutlined} from '@ant-design/icons';
import {Menu, Dropdown} from "antd";
import {connect} from "react-redux";

function getLanguageDetail(locale) {
    return ""
}

const SelectedLanguage = ({locale}) => {
    const language = getLanguageDetail(locale)
    const {langName, icon} = language;

    return (
        <div className="d-flex align-items-center">
            <img style={{maxWidth: '20px'}} src={`/img/flags/${icon}.png`} alt={langName}/>
            <span className="font-weight-semibold ml-2">{langName} <DownOutlined className="font-size-xs"/></span>
        </div>
    )
}

export const NavLanguage = ({locale, configDisplay, list, selectedCountryObj, selectedCountryFunc}) => {

    function selectedCountry(selectedCountry) {
        localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
        selectedCountryFunc(selectedCountry)
        window.document.location.reload();
    }

    const languageOption = (
        <Menu>
            {
                list.map((elm, i) => {
                    return (
                        <Menu.Item
                            key={elm.name}
                            onClick={() => selectedCountry(elm)}
                            className={selectedCountryObj.key === elm.key ? 'ant-dropdown-menu-item-active' : ''}
                        >
						<span className="d-flex justify-content-between align-items-center">
							<div>
								<img style={{maxWidth: '20px'}} src={elm.flag} alt={elm.name}/>
								<span className="font-weight-normal ml-2">{elm.name}</span>
							</div>
                            {selectedCountryObj.key === elm.key ? <CheckOutlined className="text-success"/> : null}
						</span>
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )

    return (
        <Dropdown placement="bottomRight" overlay={languageOption} trigger={["click"]}>
            {
                configDisplay ?
                    (
                        <a href="#" className="text-gray" onClick={e => e.preventDefault()}>
                            <SelectedLanguage locale={locale}/>
                        </a>
                    )
                    :
                    (
                        <Menu mode="horizontal">
                            <Menu.Item key="language">
                                <a href="#" onClick={e => e.preventDefault()}>
                                    <GlobalOutlined className="nav-icon mr-0"/>
                                </a>
                            </Menu.Item>
                        </Menu>
                    )
            }
        </Dropdown>
    )
}

const mapStateToProps = ({theme, countries}) => {
    const {locale} = theme;
    const {list, selectedCountryObj} = countries
    return {locale, list, selectedCountryObj}
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectedCountryFunc: (country) => {
            dispatch({
                type: 'SELECTED_COUNTRY',
                payload: country
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavLanguage);
