const Stores = (state = {
    stores: [],
    storesList: [],
    sortCategory: 'All',
    sortSubCategory: 'All',
    sortStatus: 'All',
    subCategories: [],
    fileList: [],
    bgImageUrl: '',
    imageUrl: '',
    modalSubCategories: [],
}, action) => {
    switch (action.type) {
        case 'UPDATE_STATE':
            return {
                ...state,
                ...action.payload
            }
            break;
        default:
            return state;
    }
};

export default Stores;
