const Payments = (state = {
    payments: [],
    paymentsList: []
}, action) => {
    switch (action.type) {
        case 'PAYMENTS':
            return {
                ...state,
                payments: action.payload
            }
            break;
        case 'PAYMENTS_LIST':
            return {
                ...state,
                paymentsList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Payments;
