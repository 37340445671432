const Location = (state = {
    storeLocation: [],
    storeLocationList: []
}, action) => {
    switch (action.type) {
        case 'GET_STORES_LOCATIONS':
            return {
                ...state,
                storeLocation: action.payload
            }
            break;
        case 'GET_STORES_LOCATIONS_LIST':
            return {
                ...state,
                storeLocationList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Location;
