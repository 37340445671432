const Interests = (state = {
    interestsList: [],
    interests: []
}, action) => {
    switch (action.type) {
        case 'GET_INTERESTS_LIST':
            return {
                ...state,
                interestsList: action.payload
            }
            break;
        case 'GET_INTERESTS':
            return {
                ...state,
                interests: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Interests;
