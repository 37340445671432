const StoresItem = (state = {
    storesItem: [],
    storesItemList: [],
    storesItemImage:''
}, action) => {
    switch (action.type) {
        case 'GET_STORES_ITEM':
            return {
                ...state,
                storesItem: action.payload
            }
            break;
        case 'GET_STORES_ITEM_LIST':
            return {
                ...state,
                storesItemList: action.payload
            }
            break;
        case 'GET_STORES_IMAGE':
            return {
                ...state,
                storesItemImage: action.payload
            }
            break;
        default:
            return state;
    }
};

export default StoresItem;
