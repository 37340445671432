const Categories = (state = {
    categories: [],
    categoriesList: [],
    subCategories: [],
    searchCategories: [],
    categoriesModal: [],
    searchSubCategories: []
}, action) => {
    switch (action.type) {
        case 'UPDATE_STATE':
            return {
                ...state,
                ...action.payload
            }
            break;
        case 'UPDATE_STATE_CATEGORY':
            return {
                ...state,
                ...action.payload
            }
            break;
        default:
            return state;
    }
};

export default Categories;
