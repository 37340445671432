const Analytics = (state = {
    analyticsBanner: [],
    analyticsBannerList: []
}, action) => {
    switch (action.type) {
        case 'GET_ANALYTICS':
            return {
                ...state,
                analyticsBanner: action.payload
            }
            break;
        case 'GET_ANALYTICS_LIST':
            return {
                ...state,
                analyticsBannerList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Analytics;
