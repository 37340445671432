const DeliveryArea = (state = {
    locations: [],
    locationsList: []
}, action) => {
    switch (action.type) {
        case 'GET_LOCATIONS':
            return {
                ...state,
                locations: action.payload
            }
            break;
        case 'GET_LOCATIONS_LIST':
            return {
                ...state,
                locationsList: action.payload
            }
            break;
        case 'UPDATE_STATE':
            return {
                ...state,
                ...action.payload
            }
            break;
        default:
            return state;
    }
};

export default DeliveryArea;
