const Trending = (state = {
    trending: [],
    trendingList: [],
    stores: [],
    storesList:[]
}, action) => {
    switch (action.type) {
        case 'GET_TRENDING':
            return {
                ...state,
                trending: action.payload
            }
            break;
        case 'GET_TRENDING_LIST':
            return {
                ...state,
                trendingList: action.payload
            }
            break;
        case 'GET_STORES':
            return {
                ...state,
                stores: action.payload
            }
            break;
        case 'GET_STORES_LIST':
            return {
                ...state,
                storesList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Trending;
