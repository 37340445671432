const Users = (state = {
    users: [],
    usersList: []
}, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                users: action.payload
            }
            break;
        case 'GET_LIST_USERS':
            return {
                ...state,
                usersList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Users;
