const Home = (state = {
    monthlyStatistics: [],
    overallStatistics: [],
    yearlyStatistics: [],
    weeklyStatistics: [],
    todayStatistics: [],
    yesterdayStatistics: []
}, action) => {
    switch (action.type) {
        case 'GET_MONTHLY_STATISTICS':
            return {
                ...state,
                monthlyStatistics: action.payload
            }
            break;
        case 'GET_OVERALL_STATISTICS':
            return {
                ...state,
                overallStatistics: action.payload
            }
            break;
        case 'GET_YEARLY_STATISTICS':
            return {
                ...state,
                yearlyStatistics: action.payload
            }
            break;
        case 'GET_WEEKLY_STATISTICS':
            return {
                ...state,
                weeklyStatistics: action.payload
            }
            break;
        case 'GET_TODAY_STATISTICS':
            return {
                ...state,
                todayStatistics: action.payload
            }
            break;
        case 'GET_YESTERDAY_STATISTICS':
            return {
                ...state,
                yesterdayStatistics: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Home;
