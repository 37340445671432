const Promotion = (state = {
    promotion: [],
    promotionList: [],

}, action) => {
    switch (action.type) {
        case 'GET_PROMOTION':
            return {
                ...state,
                promotion: action.payload
            }
            break;
        case 'GET_PROMOTION_LIST':
            return {
                ...state,
                promotionList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Promotion;
