import {combineReducers} from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Countries from "./Countries"
import Interests from "./Interests"
import DeliveryArea from "./DeliveryArea";
import Categories from "./Categories";
import Promotion from "./Promotion";
import StoresAccount from "./StoresAccount";
import Analytics from "./Analytics";
import Banner from "./Banner";
import Users from "./Users";
import Stores from "./Stores";
import Trending from "./Trending";
import Saved from "./Saved";
import Explorer from "./Explorer";
import StoresItem from "./StoresItem";
import Location from "./Location";
import Home from "./Home";
import Manager from "./Manager";
import Payments from "./Payments";
import BoostSubscription from "./BoostSubscription";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    countries: Countries,
    interests: Interests,
    deliveryArea: DeliveryArea,
    categories: Categories,
    promotion: Promotion,
    storesAccount: StoresAccount,
    analytics: Analytics,
    banner: Banner,
    users: Users,
    stores: Stores,
    trending: Trending,
    saved: Saved,
    explorer: Explorer,
    storesItem: StoresItem,
    location: Location,
    home: Home,
    manager: Manager,
    payments: Payments,
    subPayments: BoostSubscription
});

export default reducers;
