const Saved = (state = {
    saved: [],
    savedList: [],
}, action) => {
    switch (action.type) {
        case 'GET_SAVED':
            return {
                ...state,
                saved: action.payload
            }
            break;
        case 'GET_SAVED_LIST':
            return {
                ...state,
                savedList: action.payload
            }
            break;
        default:
            return state;
    }
};

export default Saved;
